import {
  Button,
  Checkbox,
  Col,
  ConfigProvider,
  DatePicker,
  Form,
  Input,
  Layout,
  Modal,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  Space,
  Upload,
  message,
  Tooltip,
} from "antd";
import { useEffect, useState } from "react";
import { Tokens, Networks, CreateIdo } from "../api/ApiCalls";
import { NetworkAPIType, TokenAPIType, CreateIdoPayload } from "../utils/types";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import { UploadOutlined } from "@ant-design/icons";
import Text from "../components/Text";
import moment from "moment";
import dayjs, { Dayjs } from "dayjs";
import { useNavigate } from "react-router-dom";
import { CloseOutlined } from "@ant-design/icons";
import { json } from "stream/consumers";
import redcross from "../assets/redcross.png";
import { QuestionCircleOutlined } from "@ant-design/icons";

const Create = () => {
  const { xs, sm, md, lg, xl, xxl } = useBreakpoint();
  const [step, setStep] = useState<number>(1);
  const [form1Values, setForm1Values] = useState<Form1Values>();
  const [form2Values, setForm2Values] = useState<Form2Values>();
  const [projectImage, setProjectImage] = useState<File>();
  const [tokenImage, setTokenImage] = useState<File>();

  return (
    <Layout
      style={{
        justifyContent: "center",
        backgroundColor: "#0C0A1F",
        alignItems: "center",
        padding: md ? "36px 48px" : sm ? "24px 24px" : " 24px 16px",
      }}
    >
      <Row
        style={{
          width: xxl
            ? "642px"
            : xl
            ? "574px"
            : lg
            ? "540px"
            : md
            ? "586px"
            : sm
            ? "504px"
            : "363px",
          padding: xxl
            ? "56px"
            : xl
            ? "36px"
            : lg
            ? "36px"
            : md
            ? "36px"
            : sm
            ? "36px"
            : "24px",
          backgroundColor: "rgb(18, 15, 45)",
          borderRadius: "12px",
          overflowY: "auto",
        }}
      >
        <Row style={{ justifyContent: "center", width: "100%" }}>
          <Text
            size="sm"
            style={{ fontSize: "20px", width: "100%", textAlign: "center" }}
          >
            Create an Offer
          </Text>
        </Row>
        {step === 1 && (
          <Step1
            setForm1Values={setForm1Values}
            setStep={setStep}
            form1Values={form1Values}
          />
        )}
        {step === 2 && (
          <Step2
            setStep={setStep}
            setForm2Values={setForm2Values}
            setProjectImage={setProjectImage}
            setTokenImage={setTokenImage}
            form2Value={form2Values}
            tokenImage={tokenImage}
            projectImage={projectImage}
          />
        )}
        {step === 3 && (
          <Step3
            form1Values={form1Values}
            form2Values={form2Values}
            projectImage={projectImage}
            tokenImage={tokenImage}
            setStep={setStep}
          ></Step3>
        )}
      </Row>
    </Layout>
  );
};

export default Create;

interface Step3Prop {
  setStep: React.Dispatch<React.SetStateAction<number>>;
  form1Values: Form1Values | undefined;
  form2Values: Form2Values | undefined;
  projectImage: File | undefined;
  tokenImage: File | undefined;
}

const Step3 = (props: Step3Prop) => {
  const { form1Values, form2Values, setStep, projectImage, tokenImage } = props;
  const [TGEflage, setTGEflage] = useState<boolean>(true);
  const [messageApi, contextHolder] = message.useMessage();
  const [createLoader, setCreateLoader] = useState<boolean>(false);
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onChange = (e: RadioChangeEvent) => {
    console.log("radio checked", e.target.value);
    setTGEflage(e.target.value);
  };

  const messageAlert = (type: "success" | "error" | "warning", msg: string) => {
    if (type === "success") {
      messageApi.open({
        type: "success",
        content: msg,
      });
    } else if (type === "error") {
      messageApi.open({
        type: "error",
        content: msg,
      });
    } else if (type === "warning") {
      messageApi.open({
        type: "warning",
        content: msg,
      });
    }
  };

  const CreateIDO = async (formData: FormData) => {
    setCreateLoader(true);
    try {
      const res = await CreateIdo(formData);
      if (res) {
        messageAlert("success", "IDO Created!");
        navigate("/");
        setCreateLoader(false);
      }
    } catch (err: any) {
      messageAlert("error", err.message);
      setCreateLoader(false);
    }
  };

  const onFinish = (values: Form3Values) => {
    const formData = new FormData();
    const now = dayjs();
    if (
      form1Values?.intersetStartTime &&
      form1Values.intersetStartTime.isBefore(now, "second")
    ) {
      showModal();
      return;
    }
    if (TGEflage) {
      formData.append("tge_percentage", values.TGEpercentage);
      formData.append(
        "tge_date",
        formatDateToUTC(values?.TgeDate?.toDate().toUTCString())
      );
    }
    const exchangeTokens = form1Values?.exchangeTokens || [];

    exchangeTokens.forEach((tokenId: string) => {
      formData.append("exchange_tokens", tokenId);
    });
    formData.append("monthly_unlock_start", values?.monthlyUnlockStart || "");
    formData.append("total_release_months", values.tokenRelaseAmount);
    formData.append("acquire_fee", values.acquireFee);

    ////////////////////////////Forms1 Data///////////////////////////////////////
    formData.append("amount", form1Values?.amount?.toString() || "");
    formData.append("price", form1Values?.price.toString() || "");
    formData.append("token_name", form1Values?.tokenName || "");
    formData.append("wallet_address", form1Values?.walletAddress || "");
    formData.append("network", form1Values?.network || "");
    formData.append("token", form1Values?.token || "");

    ////////////////////////////Form2 Data/////////////////////////////////////////
    formData.append("valuation", form2Values?.valuation || "");
    formData.append("total_supply", form2Values?.totalSupply || "");
    formData.append("initial_market_cap", form2Values?.initialMarketCap || "");
    formData.append("hard_cap", form2Values?.hardcap || "");
    formData.append("platform_raise", form2Values?.platformRaise || "");
    formData.append(
      "interest_start",
      formatDateToUTC(form1Values?.intersetStartTime?.toDate().toUTCString())
    );
    formData.append(
      "interest_end",
      formatDateToUTC(form1Values?.interestEndTime?.toDate().toUTCString())
    );
    formData.append(
      "allocation_start",
      formatDateToUTC(form1Values?.allocationStartTime?.toDate().toUTCString())
    );
    formData.append(
      "allocation_end",
      formatDateToUTC(form1Values?.allocationEndTime?.toDate().toUTCString())
    );
    formData.append(
      "fcfs_start",
      formatDateToUTC(form1Values?.fcfsStartTime?.toDate().toUTCString())
    );
    formData.append(
      "fcfs_end",
      formatDateToUTC(form1Values?.fcfsEndTime?.toDate().toUTCString())
    );

    if (tokenImage) {
      formData.append("token_image", tokenImage);
    }
    if (projectImage) {
      formData.append("project_image", projectImage);
    }
    formData.append("token_intro", form2Values?.tokenIntro || "");
    formData.append("project_overview", form2Values?.projectOverview || "");
    formData.append("revenue_model", form2Values?.revenueModal || "");
    formData.append("website", form2Values?.website || "");
    formData.append("linked_in_url", form2Values?.linkedin || "");
    formData.append("x_url", form2Values?.X || "");
    formData.append("telegram_url", form2Values?.telegram || "");
    formData.append("discord_url", form2Values?.discord || "");

    ///////////////////Calling Create Offer function /////////////////////////

    const whitelistItems = values.whitelist;
    console.log(whitelistItems);

    whitelistItems.forEach((item, index) => {
      if (item.whiteaddress !== "" && item.ACQamount !== "")
        formData.append(
          "whitelist_address",
          JSON.stringify({
            address: item.whiteaddress,
            balance: item.ACQamount,
          })
        );
    });

    CreateIDO(formData);
  };

  return (
    <Form style={{ width: "100%" }} name="step4" onFinish={onFinish}>
      {contextHolder}
      <Row style={{ marginBottom: "16px", width: "100%" }}>
        <Text size="md" style={{ fontWeight: "600" }}>
          Payouts
        </Text>

        <Row
          style={{
            flexDirection: "column",
            gap: "4px",
            width: "100%",
            marginTop: "12px",
          }}
        >
          <Text size="xs" style={{ color: "#909090" }}>
            Acquire Fee (%)
          </Text>
          <Form.Item
            name="acquireFee"
            rules={[
              {
                required: true,
                message: "Please input Acquire Fee!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (value >= 1 && value <= 100) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("The value must be between 1 and 100!")
                  );
                },
              }),
            ]}
          >
            <Input
              placeholder="0"
              size="large"
              addonAfter={<AddonText text="%" small />}
              onKeyPress={(e) => {
                const charCode = e.which ? e.which : e.keyCode;
                const value = (e.target as HTMLInputElement).value;

                if (
                  (charCode !== 46 || value.indexOf(".") !== -1) &&
                  (charCode < 48 || charCode > 57)
                ) {
                  e.preventDefault();
                }
              }}
            />
          </Form.Item>
        </Row>

        <Text
          size="xs"
          style={{
            color: "#909090",
            width: "100%",

            marginBottom: "4px",
          }}
        >
          Do you want to include a TGE payout release?
        </Text>
        <Radio.Group onChange={onChange} value={TGEflage}>
          <Radio value={true}>Yes</Radio>
          <Radio value={false}>No</Radio>
        </Radio.Group>
      </Row>

      {TGEflage === true && (
        <Row style={{ flexDirection: "column", gap: "4px", width: "100%" }}>
          <Text size="xs" style={{ color: "#909090" }}>
            TGE percentage
          </Text>
          <Form.Item
            name="TGEpercentage"
            rules={[
              {
                required: TGEflage,
                message: "Please enter a TGE percentage",
              },
            ]}
          >
            <Input
              placeholder="0"
              size="large"
              addonAfter={<AddonText text="%" small />}
              onKeyPress={(e) => {
                const charCode = e.which ? e.which : e.keyCode;
                const value = (e.target as HTMLInputElement).value;

                if (
                  (charCode !== 46 || value.indexOf(".") !== -1) &&
                  (charCode < 48 || charCode > 57)
                ) {
                  e.preventDefault();
                }
              }}
            />
          </Form.Item>
        </Row>
      )}

      {TGEflage === true && (
        <Row style={{ flexDirection: "column", gap: "4px", width: "100%" }}>
          <Text size="xs" style={{ color: "#909090" }}>
            TGE date
          </Text>
          <Form.Item
            name="TgeDate"
            rules={[
              {
                required: TGEflage,
                message: "Please pick start time",
              },
            ]}
          >
            <DatePicker
              placeholder="Date"
              showTime
              style={{ width: "100%" }}
              size="large"
            />
          </Form.Item>
        </Row>
      )}

      <Row style={{ flexDirection: "column", gap: "4px", width: "100%" }}>
        <Text size="xs" style={{ color: "#909090" }}>
          Monthly unlock start (Cliff)
        </Text>
        <Form.Item
          name="monthlyUnlockStart"
          rules={[
            {
              required: true,
              message: "Input Required!",
            },
          ]}
        >
          <Input
            placeholder="0"
            size="large"
            addonAfter={<AddonText text="Months" medium />}
            type="number"
            onKeyPress={(e) => {
              const charCode = e.which ? e.which : e.keyCode;
              const value = (e.target as HTMLInputElement).value;
            }}
          />
        </Form.Item>
      </Row>

      <Row style={{ flexDirection: "column", gap: "4px", width: "100%" }}>
        <Text size="xs" style={{ color: "#909090" }}>
          Amount of months to release tokens
        </Text>
        <Form.Item
          name="tokenRelaseAmount"
          rules={[
            {
              required: true,
              message: "Input Required!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (value >= 1 ) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("The value must be higher than 0")
                );
              },
            }),
          ]}
        >
          <Input
            placeholder="0"
            size="large"
            addonAfter={<AddonText text="Months" medium />}
            onKeyPress={(e) => {
              const charCode = e.which ? e.which : e.keyCode;
              const value = (e.target as HTMLInputElement).value;

              if (
                (charCode !== 46 || value.indexOf(".") !== -1) &&
                (charCode < 48 || charCode > 57)
              ) {
                e.preventDefault();
              }
            }}
          />
        </Form.Item>
      </Row>

      <Text size="md">Whitelisted Wallets</Text>
      <Row gutter={8} style={{ marginTop: "18px" }}>
        <Col span={12}>
          <Text size="xs" style={{ color: "#909090" }}>
            Add Whitelisted Wallet Address(es)
          </Text>
        </Col>
        <Col span={10}>
          <Text size="xs" style={{ color: "#909090" }}>
            Add ACQ Amount
          </Text>
        </Col>
      </Row>

      <Row style={{ marginTop: "12px", width: "100%" }}>
        <Form.List
          name="whitelist"
          initialValue={[{ whiteaddress: "", ACQamount: "" }]}
        >
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }, index) => (
                <Row
                  key={key}
                  gutter={8}
                  style={{ width: "100%", alignItems: "baseline" }}
                >
                  <Col span={12}>
                    <Form.Item
                      {...restField}
                      name={[name, "whiteaddress"]}
                      //rules={[{ required: true, message: "Missing first name" }]}
                    >
                      <Input placeholder="Add Wallet Address" size="large" />
                    </Form.Item>
                  </Col>
                  <Col span={10}>
                    <Form.Item
                      {...restField}
                      name={[name, "ACQamount"]}
                      // rules={[{ required: true, message: "Missing last name" }]}
                    >
                      <Input
                        placeholder="Enter ACQ amount"
                        size="large"
                        onKeyPress={onKeyPress}
                      />
                    </Form.Item>
                  </Col>
                  {index > 0 && (
                    <CloseOutlined
                      onClick={() => remove(name)}
                      style={{ color: "white" }}
                    />
                  )}
                </Row>
              ))}
              <Form.Item>
                <Text
                  size="sm"
                  style={{ color: "#33FFFF", cursor: "pointer" }}
                  onClick={() => add()}
                >
                  Add extra wallet address
                </Text>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Row>

      <Row gutter={24} style={{ width: "100%" }}>
        <Col span={12}>
          <Form.Item>
            <Button
              style={{ width: "100%", marginTop: "14px" }}
              size="large"
              onClick={() => {
                setStep(2);
              }}
            >
              Back
            </Button>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item>
            <Button
              style={{ width: "100%", marginTop: "14px" }}
              type="primary"
              htmlType="submit"
              size="large"
              loading={createLoader}
            >
              Create Offer
            </Button>
          </Form.Item>
        </Col>
      </Row>
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        footer={false}
        width={550}
        styles={{
          content: {
            padding: "20px",
            backgroundColor: "rgba(255, 255, 255, 0.30)",
          },
        }}
      >
        <Row
          style={{ justifyContent: "center", marginTop: "16px", gap: "12px" }}
        >
          <img src={redcross} />
          <h1
            style={{
              fontSize: "18px",
              color: "white",
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            You can’t publish an offer when it is already started. Make sure the
            Interest Start Time is after your current time
          </h1>
        </Row>
      </Modal>
    </Form>
  );
};

interface Step2Prop {
  setStep: React.Dispatch<React.SetStateAction<number>>;
  setForm2Values: React.Dispatch<React.SetStateAction<Form2Values | undefined>>;
  setTokenImage: React.Dispatch<React.SetStateAction<File | undefined>>;
  setProjectImage: React.Dispatch<React.SetStateAction<File | undefined>>;
  form2Value: Form2Values | undefined;
  projectImage: File | undefined;
  tokenImage: File | undefined;
}
const Step2 = (props: Step2Prop) => {
  const {
    setStep,
    form2Value,
    setForm2Values,
    setTokenImage,
    setProjectImage,
    projectImage,
    tokenImage,
  } = props;
  // const [tokenFile, setTokenFile] = useState<File>();
  // const [projectFile, setProjectFile] = useState<File>();
  const { TextArea } = Input;

  const handleFileChange = (info: any) => {
    const fileList = [...info.fileList];
    // setTokenFile(fileList.length > 0 ? fileList[0].originFileObj : null);
    setTokenImage(fileList.length > 0 ? fileList[0].originFileObj : null);
  };

  const handleProjectFileChange = (info: any) => {
    const fileList = [...info.fileList];
    // setProjectFile(fileList.length > 0 ? fileList[0].originFileObj : null);
    setProjectImage(fileList.length > 0 ? fileList[0].originFileObj : null);
  };

  const onFinish = (values: Form2Values) => {
    setForm2Values(values);
    setStep(3);
  };

  return (
    <Row style={{ width: "100%", marginTop: "20px" }}>
      <Form
        name="step3"
        style={{ width: "100%" }}
        onFinish={onFinish}
        initialValues={{
          tokenIntro: form2Value?.tokenIntro,
          projectOverview: form2Value?.projectOverview,
          revenueModal: form2Value?.revenueModal,
          website: form2Value?.website,
          linkedin: form2Value?.linkedin,
          X: form2Value?.X,
          telegram: form2Value?.telegram,
          discord: form2Value?.discord,
          valuation: form2Value?.valuation,
          totalSupply: form2Value?.totalSupply,
          initialMarketCap: form2Value?.initialMarketCap,
          hardcap: form2Value?.hardcap,
          platformRaise: form2Value?.platformRaise,
        }}
      >
        <Text size="md" style={{ fontWeight: "600" }}>
          Project information
        </Text>
        <ConfigProvider
          theme={{
            components: {
              Button: {
                defaultBg: "#120F2D",
                defaultColor: "White",
              },
            },
          }}
        >
          {/* <Row style={{ flexDirection: "column", gap: "8px" }}>
            <Text size="xs" style={{ color: "#909090" }}>
              Token image
            </Text>
            <Form.Item
              name="AA"
              rules={[
                {
                  required: true,
                  message: "Please upload Image",
                },
              ]}
              style={{ width: "100%" }}
            >
              <Upload onChange={handleFileChange} beforeUpload={() => false}>
                <Button icon={<UploadOutlined />} style={{ width: "100%" }}>
                  Upload
                </Button>
              </Upload>
            </Form.Item>
          </Row> */}

          <Row
            style={{ flexDirection: "column", gap: "8px", marginTop: "12px" }}
          >
            <Text size="xs" style={{ color: "#909090" }}>
              Project image
            </Text>
            <Form.Item
              name="BB"
              rules={[
                {
                  required: true,
                  message: "Please upload Image",
                },
              ]}
              style={{ width: "100%" }}
            >
              <Upload
                onChange={handleProjectFileChange}
                beforeUpload={() => false}
              >
                <Button icon={<UploadOutlined />} style={{ width: "100%" }}>
                  Upload
                </Button>
              </Upload>
            </Form.Item>
          </Row>
        </ConfigProvider>
        <Form.Item
          name="valuation"
          // rules={[
          //   {
          //     required: true,
          //     message: "Please input Valuation!",
          //   },
          // ]}
        >
          <Input
            placeholder="0.00"
            size="large"
            addonAfter={<AddonText text="Valuation" />}
            onKeyPress={(e) => {
              const charCode = e.which ? e.which : e.keyCode;
              const value = (e.target as HTMLInputElement).value;

              if (
                (charCode !== 46 || value.indexOf(".") !== -1) &&
                (charCode < 48 || charCode > 57)
              ) {
                e.preventDefault();
              }
            }}
          />
        </Form.Item>

        <Form.Item
          name="totalSupply"
          // rules={[
          //   {
          //     required: true,
          //     message: "Please input total supply!",
          //   },
          // ]}
        >
          <Input
            placeholder="0.00"
            size="large"
            addonAfter={<AddonText text="Total supply" />}
            onKeyPress={(e) => {
              const charCode = e.which ? e.which : e.keyCode;
              const value = (e.target as HTMLInputElement).value;

              if (
                (charCode !== 46 || value.indexOf(".") !== -1) &&
                (charCode < 48 || charCode > 57)
              ) {
                e.preventDefault();
              }
            }}
          />
        </Form.Item>

        <Form.Item
          name="initialMarketCap"
          // rules={[
          //   {
          //     required: true,
          //     message: "Please input initial market cap!",
          //   },
          // ]}
        >
          <Input
            placeholder="0.00"
            size="large"
            addonAfter={<AddonText text="Initial Market cap" />}
            onKeyPress={(e) => {
              const charCode = e.which ? e.which : e.keyCode;
              const value = (e.target as HTMLInputElement).value;

              if (
                (charCode !== 46 || value.indexOf(".") !== -1) &&
                (charCode < 48 || charCode > 57)
              ) {
                e.preventDefault();
              }
            }}
          />
        </Form.Item>

        <Form.Item
          name="hardcap"
          // rules={[
          //   {
          //     required: true,
          //     message: "Please input hard cap!",
          //   },
          // ]}
        >
          <Input
            placeholder="0.00"
            size="large"
            addonAfter={<AddonText text="Allocation Max" />}
            onKeyPress={(e) => {
              const charCode = e.which ? e.which : e.keyCode;
              const value = (e.target as HTMLInputElement).value;

              if (
                (charCode !== 46 || value.indexOf(".") !== -1) &&
                (charCode < 48 || charCode > 57)
              ) {
                e.preventDefault();
              }
            }}
          />
        </Form.Item>

        <Form.Item
          name="platformRaise"
          // rules={[
          //   {
          //     required: true,
          //     message: "Please input platform raise!",
          //   },
          // ]}
        >
          <Input
            placeholder="0.00"
            size="large"
            addonAfter={<AddonText text="Platform raise" />}
            onKeyPress={(e) => {
              const charCode = e.which ? e.which : e.keyCode;
              const value = (e.target as HTMLInputElement).value;

              if (
                (charCode !== 46 || value.indexOf(".") !== -1) &&
                (charCode < 48 || charCode > 57)
              ) {
                e.preventDefault();
              }
            }}
          />
        </Form.Item>

        <Row style={{ flexDirection: "column", gap: "8px" }}>
          <Text size="xs" style={{ color: "#909090" }}>
            Token intro text
          </Text>
          <Form.Item
            name="tokenIntro"
            // rules={[
            //   {
            //     required: true,
            //     message: "Please input into text",
            //   },
            // ]}
            style={{ width: "100%" }}
          >
            <TextArea rows={4} placeholder="Enter a intro text" />
          </Form.Item>
        </Row>

        <Row style={{ flexDirection: "column", gap: "8px" }}>
          <Text size="xs" style={{ color: "#909090" }}>
            Project overview
          </Text>
          <Form.Item
            name="projectOverview"
            // rules={[
            //   {
            //     required: true,
            //     message: "Please input project overview",
            //   },
            // ]}
            style={{ width: "100%" }}
          >
            <TextArea rows={4} placeholder="Enter a project overview text" />
          </Form.Item>
        </Row>

        <Row style={{ flexDirection: "column", gap: "8px" }}>
          <Text size="xs" style={{ color: "#909090" }}>
            Revenue model
          </Text>
          <Form.Item
            name="revenueModal"
            // rules={[
            //   {
            //     required: true,
            //     message: "Please input revenue",
            //   },
            // ]}
            style={{ width: "100%" }}
          >
            <TextArea rows={4} placeholder="Enter a revenue model text" />
          </Form.Item>
        </Row>

        <Row style={{ flexDirection: "column", gap: "8px" }}>
          <Text size="xs" style={{ color: "#909090" }}>
            Website
          </Text>
          <Form.Item
            name="website"
            rules={[
              {
                validator: validateDiscordUrl,
              },
            ]}
          >
            <Input placeholder="Enter a website url" size="large" />
          </Form.Item>
        </Row>

        <Row style={{ flexDirection: "column", gap: "8px" }}>
          <Text size="xs" style={{ color: "#909090" }}>
            LinkedIn
          </Text>
          <Form.Item
            name="linkedin"
            rules={[
              {
                validator: validateDiscordUrl,
              },
            ]}
          >
            <Input placeholder="Enter a LinkedIn url" size="large" />
          </Form.Item>
        </Row>

        <Row style={{ flexDirection: "column", gap: "8px" }}>
          <Text size="xs" style={{ color: "#909090" }}>
            X
          </Text>
          <Form.Item
            name="X"
            rules={[
              {
                validator: validateDiscordUrl,
              },
            ]}
          >
            <Input placeholder="Enter a X url" size="large" />
          </Form.Item>
        </Row>

        <Row style={{ flexDirection: "column", gap: "8px" }}>
          <Text size="xs" style={{ color: "#909090" }}>
            Telegram
          </Text>
          <Form.Item
            name="telegram"
            rules={[
              {
                validator: validateDiscordUrl,
              },
            ]}
          >
            <Input placeholder="Enter a Telegram url" size="large" />
          </Form.Item>
        </Row>

        <Row style={{ flexDirection: "column", gap: "8px" }}>
          <Text size="xs" style={{ color: "#909090" }}>
            Discord
          </Text>
          <Form.Item
            name="discord"
            rules={[
              {
                validator: validateDiscordUrl,
              },
            ]}
          >
            <Input placeholder="Enter a Discord url" size="large" />
          </Form.Item>
        </Row>

        <Row gutter={24}>
          <Col span={12}>
            <Form.Item>
              <Button
                style={{ width: "100%", marginTop: "14px" }}
                size="large"
                onClick={() => {
                  setStep(1);
                }}
              >
                Back
              </Button>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item>
              <Button
                style={{ width: "100%", marginTop: "14px" }}
                type="primary"
                htmlType="submit"
                size="large"
              >
                Step 2
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Row>
  );
};

interface Ste1Prop {
  setForm1Values: React.Dispatch<React.SetStateAction<Form1Values | undefined>>;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  form1Values: Form1Values | undefined;
}
const Step1 = (props: Ste1Prop) => {
  const { setForm1Values, setStep, form1Values } = props;

  const { Option } = Select;
  const [network, setNetwork] = useState<NetworkAPIType[]>([]);
  const [token, setToken] = useState<TokenAPIType[]>([]);
  const [tokenSuffix, setTokenSuffix] = useState<string>();

  const GetTokens = async () => {
    try {
      const res = await Tokens();
      if (res) {
        // console.log("Token API res ", res);
        setToken(res);
      }
    } catch (err) {
      console.log("Error While Fetch Token API", err);
    }
  };

  const GetNetworks = async () => {
    try {
      const res = await Networks();
      if (res) {
        //console.log("Networks API res ", res);
        setNetwork(res);
      }
    } catch (err) {
      console.log("Error While Fetch Networks API", err);
    }
  };

  useEffect(() => {
    GetTokens();
    GetNetworks();
  }, []);

  const handleTokenChange = (tokenid: string) => {
    const selectedToken = token.find((t) => t.id === tokenid);

    if (selectedToken) {
      setTokenSuffix(selectedToken.symbol);
    }
  };

  const onFinish = (values: Form1Values) => {
    console.log("Total Amount", values.amount);
    console.log("Price", values.price);
    console.log("token name", values.tokenName);
    console.log("wallet address", values.walletAddress);
    console.log("network", values.network);
    console.log("token", values.token);
    console.log("exhange token", values.exchangeTokens);
    setForm1Values(values);
    setStep(2);
  };

  return (
    <Row style={{ width: "100%", marginTop: "20px" }}>
      <Form
        name="step1"
        onFinish={onFinish}
        style={{ width: "100%" }}
        initialValues={form1Values}
      >
        <Text size="md" style={{ fontWeight: "600" }}>
          IDO information
        </Text>

        <Form.Item
          name="tokenName"
          rules={[
            {
              required: true,
              message: "Please input token name!",
            },
          ]}
          style={{ marginTop: "12px" }}
        >
          <Input
            placeholder="Enter a name"
            size="large"
            addonAfter={<AddonText text="Offer name" />}
          />
        </Form.Item>

        <Row style={{ flexDirection: "column", gap: "4px" }}>
          <Text size="xs" style={{ color: "#909090" }}>
            User Receive token
          </Text>
          <Form.Item
            name="token"
            rules={[{ required: true, message: "Please select token!" }]}
          >
            <Select
              placeholder="Select a token"
              size="large"
              onChange={handleTokenChange}
            >
              {token
                .filter((token) => token.send_token === false)
                .map((token) => (
                  <Option key={token.id} value={token.id}>
                    {token.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Row>

        <Form.Item
          name="amount"
          rules={[
            {
              required: true,
              message: "Please input Tokens to sell!",
            },
          ]}
        >
          <Input
            placeholder="0.00"
            suffix={tokenSuffix}
            size="large"
            addonAfter={<AddonText text="Tokens To Sell" />}
            onKeyPress={(e) => {
              const charCode = e.which ? e.which : e.keyCode;
              const value = (e.target as HTMLInputElement).value;

              if (
                (charCode !== 46 || value.indexOf(".") !== -1) &&
                (charCode < 48 || charCode > 57)
              ) {
                e.preventDefault();
              }
            }}
          />
        </Form.Item>

        <Form.Item
          name="price"
          rules={[
            {
              required: true,
              message: "Please input price!",
            },
          ]}
        >
          <Input
            placeholder="0.00"
            size="large"
            addonAfter={<AddonText text="Launch Price" />}
            onKeyPress={(e) => {
              const charCode = e.which ? e.which : e.keyCode;
              const value = (e.target as HTMLInputElement).value;

              if (
                (charCode !== 46 || value.indexOf(".") !== -1) &&
                (charCode < 48 || charCode > 57)
              ) {
                e.preventDefault();
              }
            }}
          />
        </Form.Item>

        <Form.Item
          name="walletAddress"
          rules={[
            {
              required: true,
              message: "Please input Deposit Address!",
            },
          ]}
        >
          <Input
            placeholder="Enter a Deposit Address"
            size="large"
            addonAfter={<AddonText text="Deposit Address" />}
          />
        </Form.Item>

        <Row style={{ flexDirection: "column", gap: "4px" }}>
          <div style={{display:'flex', flexDirection:'row', gap:"8px"}}>
          <Text size="xs" style={{ color: "#909090" }}>
            Specify output wallet chain (chain user pays with)
          </Text>
          <Tooltip className="tooltip" style={{color:'#909090'}} title="Select the chain the user will use to make a transaction using the valid stable coin(s).">
            <QuestionCircleOutlined />
          </Tooltip>
          </div>
          <Form.Item
            name="network"
            rules={[{ required: true, message: "Please select network!" }]}
          >
            <Select placeholder="Select a network" size="large">
              {network.map((token) => (
                <Option key={token.name} value={token.id}>
                  {token.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Row>

        <Row style={{ flexDirection: "column", gap: "4px" }}>
          <Text size="xs" style={{ color: "#909090" }}>
            Valid Stable Coins
          </Text>
          <Form.Item
            name="exchangeTokens"
            rules={[
              { required: true, message: "Please select at least one token!" },
            ]}
          >
            <Select
              placeholder="Select tokens"
              size="large"
              mode="multiple"
              showSearch
              optionFilterProp="children"
            >
              {token
                .filter((token) => token.send_token === true)
                .map((token) => (
                  <Option key={token.id} value={token.id}>
                    {token.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Row>

        <Row gutter={24}>
          <Col span={12}>
            <Row style={{ flexDirection: "column", gap: "4px" }}>
              <Text size="xs" style={{ color: "#909090" }}>
                Interest Start time
              </Text>
              <Form.Item
                name="intersetStartTime"
                rules={[
                  {
                    required: true,
                    message: "Please pick start time",
                  },
                ]}
              >
                <DatePicker
                  placeholder="Start Date"
                  showTime
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Row>
          </Col>
          <Col span={12}>
            <Row style={{ flexDirection: "column", gap: "4px" }}>
              <Text size="xs" style={{ color: "#909090" }}>
                Interest End time
              </Text>
              <Form.Item
                name="interestEndTime"
                dependencies={["intersetStartTime"]}
                rules={[
                  {
                    required: true,
                    message: "Please pick end time",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const startTime = getFieldValue("intersetStartTime");
                      if (!value || !startTime) {
                        return Promise.resolve();
                      }
                      const startDate = dayjs(startTime);
                      const endDate = dayjs(value);
                      if (
                        endDate.isBefore(startDate) ||
                        endDate.isSame(startDate, "second")
                      ) {
                        console.log("value", value);
                        console.log("start", startTime);
                        return Promise.reject(
                          new Error(
                            "Interest End must be after Interest Start time"
                          )
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <DatePicker
                  placeholder="End Date"
                  showTime
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Row>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={12}>
            <Row style={{ flexDirection: "column", gap: "4px" }}>
              <Text size="xs" style={{ color: "#909090" }}>
                Allocation Start time
              </Text>
              <Form.Item
                name="allocationStartTime"
                dependencies={["interestEndTime"]}
                rules={[
                  {
                    required: true,
                    message: "Please pick start time",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const startTime = getFieldValue("interestEndTime");
                      if (!value || !startTime) {
                        return Promise.resolve();
                      }
                      const startDate = dayjs(startTime);
                      const endDate = dayjs(value);
                      if (
                        endDate.isBefore(startDate) ||
                        endDate.isSame(startDate, "second")
                      ) {
                        console.log("value", value);
                        console.log("start", startTime);
                        return Promise.reject(
                          new Error(
                            "Allocation Start must be after Interest End"
                          )
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <DatePicker
                  placeholder="Start Date"
                  showTime
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Row>
          </Col>
          <Col span={12}>
            <Row style={{ flexDirection: "column", gap: "4px" }}>
              <Text size="xs" style={{ color: "#909090" }}>
                Allocation End time
              </Text>
              <Form.Item
                name="allocationEndTime"
                dependencies={["allocationStartTime"]}
                rules={[
                  {
                    required: true,
                    message: "Please pick end time",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const startTime = getFieldValue("allocationStartTime");
                      if (!value || !startTime) {
                        return Promise.resolve();
                      }
                      const startDate = dayjs(startTime);
                      const endDate = dayjs(value);
                      if (
                        endDate.isBefore(startDate) ||
                        endDate.isSame(startDate, "second")
                      ) {
                        console.log("value", value);
                        console.log("start", startTime);
                        return Promise.reject(
                          new Error(
                            "Allocation End must be after Allocation Start"
                          )
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <DatePicker
                  placeholder="End Date"
                  showTime
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Row>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={12}>
            <Row style={{ flexDirection: "column", gap: "4px" }}>
              <Text size="xs" style={{ color: "#909090" }}>
                FCFS Start time
              </Text>
              <Form.Item
                name="fcfsStartTime"
                dependencies={["allocationEndTime"]}
                rules={[
                  {
                    required: true,
                    message: "Please pick start time",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const startTime = getFieldValue("allocationEndTime");
                      if (!value || !startTime) {
                        return Promise.resolve();
                      }
                      const startDate = dayjs(startTime);
                      const endDate = dayjs(value);
                      if (
                        endDate.isBefore(startDate) ||
                        endDate.isSame(startDate, "second")
                      ) {
                        console.log("value", value);
                        console.log("start", startTime);
                        return Promise.reject(
                          new Error("FCFS Start must be after Allocation End")
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <DatePicker
                  placeholder="Start Date"
                  showTime
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Row>
          </Col>
          <Col span={12}>
            <Row style={{ flexDirection: "column", gap: "4px" }}>
              <Text size="xs" style={{ color: "#909090" }}>
                FCFS End time
              </Text>
              <Form.Item
                name="fcfsEndTime"
                dependencies={["fcfsStartTime"]}
                rules={[
                  {
                    required: true,
                    message: "Please pick end time",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const startTime = getFieldValue("fcfsStartTime");
                      if (!value || !startTime) {
                        return Promise.resolve();
                      }
                      const startDate = dayjs(startTime);
                      const endDate = dayjs(value);
                      if (
                        endDate.isBefore(startDate) ||
                        endDate.isSame(startDate, "second")
                      ) {
                        console.log("value", value);
                        console.log("start", startTime);
                        return Promise.reject(
                          new Error("FCFS End must be after FCFS Start")
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <DatePicker
                  placeholder="End Date"
                  showTime
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Row>
          </Col>
        </Row>

        <Form.Item>
          <Button
            style={{ width: "100%", marginTop: "14px" }}
            type="primary"
            htmlType="submit"
            size="large"
          >
            Step 2
          </Button>
        </Form.Item>
      </Form>
    </Row>
  );
};

///////////////////////////////////////////////////Form onfinish Interfaces//////////////////////////////////

interface Form1Values {
  amount: number;
  price: number;
  tokenName: string;
  walletAddress: string;
  network: string;
  token: string;
  exchangeTokens: string[];
  intersetStartTime?: Dayjs;
  interestEndTime?: Dayjs;
  allocationStartTime?: Dayjs;
  allocationEndTime?: Dayjs;
  fcfsStartTime?: Dayjs;
  fcfsEndTime?: Dayjs;
}

type Form2Values = {
  tokenIntro: string;
  projectOverview: string;
  revenueModal: string;
  website: string;
  linkedin: string;
  X: string;
  telegram: string;
  discord: string;
  valuation?: string;
  totalSupply?: string;
  initialMarketCap?: string;
  hardcap?: string;
  platformRaise?: string;
};

type Form3Values = {
  TGEpercentage: string;
  TgeDate: Dayjs;
  monthlyUnlockStart: string;
  tokenRelaseAmount: string;
  whitelist: WhitelistItem[];
  acquireFee: string;
};

type WhitelistItem = {
  whiteaddress: string;
  ACQamount: string;
};

const AddonText = ({
  text,
  small,
  medium,
}: {
  text: string;
  small?: boolean;
  medium?: boolean;
}) => {
  return (
    <div
      style={{
        width: small ? "40px" : medium ? "60px" : "140px",
        height: "100%",
      }}
    >
      <Text size="sm">{text}</Text>
    </div>
  );
};

function formatDate(date: Date | null | undefined) {
  if (!date || isNaN(date.getTime())) {
    return ""; // Return empty string if date is not valid or not provided
  }

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hour = String(date.getHours()).padStart(2, "0");
  const minute = String(date.getMinutes()).padStart(2, "0");
  const second = String(date.getSeconds()).padStart(2, "0");
  return `${year}-${month}-${day}T${hour}:${minute}:${second}`;
}

const validateDiscordUrl = (rule: any, value: any, callback: any) => {
  if (value && !value.includes("http://") && !value.includes("https://")) {
    callback('URL must start with "http://" or "https://"');
  } else {
    callback(); // Validation passed
  }
};

function formatDateToUTC(inputDate: any) {
  const date = new Date(inputDate);

  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, "0");
  const day = String(date.getUTCDate()).padStart(2, "0");
  const hours = String(date.getUTCHours()).padStart(2, "0");
  const minutes = String(date.getUTCMinutes()).padStart(2, "0");
  const seconds = String(date.getUTCSeconds()).padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;

  return formattedDate;
}

export const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
  const charCode = e.which ? e.which : e.keyCode;
  const value = (e.target as HTMLInputElement).value;

  if (
    (charCode !== 46 || value.indexOf(".") !== -1) &&
    (charCode < 48 || charCode > 57)
  ) {
    e.preventDefault();
  }
};
