import React, { useEffect, useState } from "react";
import {
  Tabs,
  Layout,
  Row,
  Button,
  Table,
  message,
  Input,
  ConfigProvider,
  Tooltip,
  Space,
  Modal,
  Select,
} from "antd";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import Text from "../components/Text";
import { useLocation, useParams } from "react-router-dom";
import { getPayouts } from "../api/ApiCalls/idos";
import Web3 from "web3";
import { useAccount } from "wagmi";
import { erc20Abi } from "viem";
import BigNumber from "bignumber.js";
import { bulkApprovePayouts, fulfillPayout } from "../api/ApiCalls";
import { fromReadableAmount, toReadableAmount } from "../utils";
import Countdown from "../components/Countdown";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { feeCal } from "../Services/FeeCalculation";
import { fulfillManualPayout } from "../api/ApiCalls/allocations";

const { TabPane } = Tabs;

function shortenString(str: string, length = 4) {
  if (str.length <= length * 2 + 3) {
    return str;
  }
  const start = str.substring(0, length);
  const end = str.substring(str.length - length);
  return `${start}...${end}`;
}

const PayoutDetail = () => {
  const { xs, sm, md, lg, xl, xxl } = useBreakpoint();
  const [activeTab, setActiveTab] = useState("actionNeeded");
  const [approveTxHash, setApproveTxHash] = useState("");
  const [payouts, setPayouts] = useState<any>([]);
  const [loading, setLoading] = useState<any>(null);
  const [loadingRow, setLoadingRow] = useState(null);

  const [approveDisabled, setApproveDisabled] = useState<boolean>(true);
  const { connector, address } = useAccount();
  const location = useLocation();
  const { token_name } = location.state || {};

  const { id } = useParams();

  const [isManualModalVisible, setIsManualModalVisible] = useState(false);
  const [manualTxHash, setManualTxHash] = useState("");
  const [selectedPayout, setSelectedPayout] = useState<any>(null);

  const [isExportModalVisible, setIsExportModalVisible] = useState(false);
  const [selectedPayoutPart, setSelectedPayoutPart] = useState<number | null>(
    null
  );

  const handleTabChange = (key: any) => {
    setActiveTab(key);
  };

  const loadPayouts = async () => {
    const payouts = await getPayouts(id || "0");

    console.log("PAYOUT", payouts);

    if (payouts) {
      const payoutAddress = payouts[0]?.allocation?.ido?.token?.address;
      const disable = await isERC20Token(payoutAddress, payouts[0]);
      setApproveDisabled(!disable);
    }
    setPayouts(payouts);
  };

  const transferTokens = async (record: any) => {
    setLoading(true);
    setLoadingRow(record?.id);

    // Get provider from connector or fallback to rpc_url
    const provider: any = await (connector?.getProvider() ||
      record?.allocation?.ido?.network?.rpc_url);

    // Initialize Web3 with the provider
    const web3 = new Web3(provider);

    const contract = new web3.eth.Contract(
      erc20Abi,
      record?.allocation?.ido?.token?.address
    );

    // calculate  subtract ido fee  of total amount  and subtract it from record.amount
    const totalAmount = new BigNumber(record.amount);
    const idoFee = totalAmount.multipliedBy(
      record?.allocation?.ido?.acquire_fee / 100
    );
    const amountToTransfer = totalAmount.minus(idoFee);

    const amountReceived = fromReadableAmount(
      amountToTransfer.toNumber(),
      record?.allocation?.ido?.token?.decimals || 18
    );

    try {
      const transaction = await contract.methods
        .transfer(
          record?.allocation?.owner?.wallet_address,
          amountReceived.toString()
        )
        .send({ from: address });

      await fulfillPayout(record.id, transaction.transactionHash);
      await loadPayouts();
      message.success("Allocation Fulfilled");
    } catch (error) {
      console.error("Error transferring tokens:", error);
    }

    setLoading(false);
    setLoadingRow(null);
  };

  async function isERC20Token(address: string, record: any) {
    if (await connector?.getProvider()) {
      try {
        const web3 = new Web3(
          (await connector?.getProvider()) ||
            record?.allocation?.ido?.network?.rpc_url
        );

        const contract = new web3.eth.Contract(
          [
            {
              constant: true,
              inputs: [],
              name: "name",
              outputs: [{ name: "", type: "string" }],
              payable: false,
              stateMutability: "view",
              type: "function",
            },
            {
              constant: true,
              inputs: [],
              name: "symbol",
              outputs: [{ name: "", type: "string" }],
              payable: false,
              stateMutability: "view",
              type: "function",
            },
          ],
          address
        );

        const name = await contract.methods.name().call();
        const symbol = await contract.methods.symbol().call();

        return !!name && !!symbol;
      } catch (err) {
        return false;
      }
    }
  }

  const columns = [
    {
      title: "User wallet",
      key: "wallet_address",
      render: (text: any, record: any) => (
        <span>{shortenString(record?.allocation?.owner?.wallet_address)}</span>
      ),
    },
    {
      title: (
        <Row style={{ gap: "5px", alignItems: "center" }}>
          Amount to pay out
          <Tooltip title="The amount you will pay out now with applied Acquire fee">
            <QuestionCircleOutlined />
          </Tooltip>
        </Row>
      ),
      //dataIndex: "amount",
      key: "amount",
      // render: (text: any, record: any) => <span>{text}</span>,
      render: (record: any) => (
        <Text size="sm">
          {feeCal(
            Number(record?.allocation?.ido?.acquire_fee),
            Number(record?.amount)
          )}{" "}
          {record?.allocation?.ido?.token?.symbol}
          {/* {record?.amount} {record?.allocation?.ido?.token?.symbol} */}
        </Text>
      ),
    },
    {
      title: (
        <Row style={{ gap: "5px", alignItems: "center" }}>
          Total Amount
          <Tooltip title="Total amount with applied Acquire fee">
            <QuestionCircleOutlined />
          </Tooltip>
        </Row>
      ),
      // dataIndex: "total_amount",
      key: "total_amount",
      render: (record: any) => (
        <Text size="sm">
          {feeCal(
            Number(record?.allocation?.ido?.acquire_fee),
            Number(record?.total_amount)
          )}{" "}
          {record?.allocation?.ido?.token?.symbol}
          {/* {record?.total_amount} {record?.allocation?.ido?.token?.symbol} */}
        </Text>
      ),
    },

    {
      title: "Payout Part",
      dataIndex: "payout_part",
      key: "payout_part",
      render: (text: any, record: any) => {
        const totalReleaseMonths = record?.allocation?.ido?.total_release_months ?? 0;
        const tge = record?.allocation?.ido?.tge;
    
        // Adjust total release months if tge has a value (is not null or undefined)
        const adjustedTotalReleaseMonths = (tge) ? totalReleaseMonths + 1 : totalReleaseMonths;
    
        return (
          <span>
            {text} of {adjustedTotalReleaseMonths}
          </span>
        );
      },
    },
    {
      title: (
        <>
          <span>Action </span>
          <Tooltip title="Is the button disabled? Check if your wallet is connected, you've selected the right network and if you've entered a (valid) token address in the Token page.">
            <QuestionCircleOutlined
              style={{ color: "#ffffff", marginLeft: "5px" }}
            />
          </Tooltip>
        </>
      ),
      key: "action",
      render: (text: any, record: any) => (
        <Space>
          <Button
            onClick={() => transferTokens(record)}
            type="primary"
            loading={loadingRow === record?.id}
            disabled={approveDisabled}
          >
            Approve Payout
          </Button>
          <Button
          className="manual-btn"
            onClick={() => {
              setSelectedPayout(record);
              setIsManualModalVisible(true);
            }}
          >
            Manual
          </Button>
        </Space>
      ),
    },
  ];

  const upcoming_columns = [
    {
      title: "User wallet",
      key: "wallet_address",
      render: (text: any, record: any) => (
        <span>{shortenString(record?.allocation?.owner?.wallet_address)}</span>
      ),
    },
    {
      title: (
        <Row style={{ gap: "5px", alignItems: "center" }}>
          Amount to pay out
          <Tooltip title="The amount you will pay out now with applied Acquire fee">
            <QuestionCircleOutlined />
          </Tooltip>
        </Row>
      ),
      dataIndex: "amount",
      key: "amount",
      render: (text: any, record: any) => (
        <span>
          {feeCal(Number(record?.allocation?.ido?.acquire_fee), Number(text))}{" "}
          {/* {text}  */}(
          {Number(
            (Number(text) / Number(record?.allocation?.amount_received)) * 100
          ).toFixed(1)}
          %)
          {"  "}
          {record?.allocation?.ido?.token?.symbol}
        </span>
      ),
    },
    {
      title: (
        <Row style={{ gap: "5px", alignItems: "center" }}>
          Total Amount
          <Tooltip title="Total amount with applied Acquire fee">
            <QuestionCircleOutlined />
          </Tooltip>
        </Row>
      ),
      //dataIndex: "total_amount",
      key: "total_amount",
      render: (record: any) => (
        <Text size="sm">
          {feeCal(
            Number(record?.allocation?.ido?.acquire_fee),
            Number(record?.total_amount)
          )}{" "}
          {record?.allocation?.ido?.token?.symbol}
          {/* {record?.total_amount} {record?.allocation?.ido?.token?.symbol} */}
        </Text>
      ),
    },

    {
      title: "Payout Part",
      dataIndex: "payout_part",
      key: "payout_part",
      render: (text: any, record: any) => {
        const totalReleaseMonths = record?.allocation?.ido?.total_release_months ?? 0;
        const tge = record?.allocation?.ido?.tge;
    
        // Adjust total release months if tge has a value (is not null or undefined)
        const adjustedTotalReleaseMonths = (tge) ? totalReleaseMonths + 1 : totalReleaseMonths;
    
        return (
          <span>
            {text} of {adjustedTotalReleaseMonths}
          </span>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      render: (text: any, record: any) => (
        <Row style={{ flexDirection: "column" }}>
          <Text size="sm">Payout action in:</Text>
          <Text size="sm">
            <Countdown endDateString={record.unlock_date} />
          </Text>
        </Row>
      ),
    },
  ];

  function formatDate(dateString: string) {
    // Parse the date string into a datetime object
    const dateObj = new Date(dateString);

    // Format the date object into the desired format (%d - day of month, %b - abbreviated month name, %Y - full year)
    const formattedDate = dateObj.toLocaleDateString("en-US", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });

    return formattedDate;
  }

  const approved_columns = [
    {
      title: "User wallet",
      key: "wallet_address",
      render: (text: any, record: any) => (
        <span>{shortenString(record.allocation.owner.wallet_address)}</span>
      ),
    },
    {
      title: (
        <Row style={{ gap: "5px", alignItems: "center" }}>
          Amount to pay out
          <Tooltip title="The amount you will pay out now with applied Acquire fee">
            <QuestionCircleOutlined />
          </Tooltip>
        </Row>
      ),
      dataIndex: "amount",
      key: "amount",
      render: (text: any, record: any) => (
        <span>
          {feeCal(Number(record?.allocation?.ido?.acquire_fee), Number(text))}{" "}
          {/* {text}  */}(
          {Number(
            (Number(text) / Number(record.allocation.amount_received)) * 100
          ).toFixed(1)}
          %)
          {"  "}
          {record?.allocation?.ido?.token?.symbol}
        </span>
      ),
    },
    {
      title: (
        <Row style={{ gap: "5px", alignItems: "center" }}>
          Total Amount
          <Tooltip title="Total amount with applied Acquire fee">
            <QuestionCircleOutlined />
          </Tooltip>
        </Row>
      ),
      //dataIndex: "amount",
      key: "amount",
      render: (record: any) => (
        <Text size="sm">
          {feeCal(
            Number(record?.allocation?.ido?.acquire_fee),
            Number(record?.total_amount)
          )}{" "}
          {record?.allocation?.ido?.token?.symbol}
          {/* {record?.total_amount} {record?.allocation?.ido?.token?.symbol} */}
        </Text>
      ),
    },

    {
      title: "Tx hash",
      dataIndex: "fulfilled_transaction_hash",
      key: "fulfilled_transaction_hash",
      render: (text: any, record: any) => (
        <span
          style={{ cursor: "pointer" }}
          onClick={() =>
            window.open(
              `${record?.allocation?.ido?.token?.network?.explorer_url}${text}`,
              "_blank"
            )
          }
        >
          {shortenString(text)}
        </span>
      ),
    },
    {
      title: "Payout Date",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (text: any, record: any) => <span> {formatDate(text)}</span>,
    },
    {
      title: "Payout Part",
      dataIndex: "payout_part",
      key: "payout_part",
      render: (text: any, record: any) => {
        const totalReleaseMonths = record?.allocation?.ido?.total_release_months ?? 0;
        const tge = record?.allocation?.ido?.tge;
    
        // Adjust total release months if tge has a value (is not null or undefined)
        const adjustedTotalReleaseMonths = (tge) ? totalReleaseMonths + 1 : totalReleaseMonths;
    
        return (
          <span>
            {text} of {adjustedTotalReleaseMonths}
          </span>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      render: (text: any, record: any) => (
        <Button type="dashed">Payout approved</Button>
      ),
    },
  ];

  useEffect(() => {
    if (id) {
      loadPayouts();
    }
  }, [id]);

  const filteredPayouts = payouts.filter((payout: any) => {
    return !payout.fulfilled && new Date(payout.unlock_date) <= new Date();
  });

  const upcomingPayouts = payouts.filter((payout: any) => {
    return !payout.fulfilled && new Date(payout.unlock_date) > new Date();
  });

  const approvedPayouts = payouts.filter((payout: any) => {
    return payout.fulfilled && new Date(payout.unlock_date) <= new Date();
  });

  const exportToCSV = () => {
    const csvRows = [];
    const headers = ["recipient", "amount", "token_address", "token_name"];
    csvRows.push(headers.join(","));
    const actionNeededPayouts = payouts.filter((payout: any) => {
      return !payout.fulfilled && new Date(payout.unlock_date) <= new Date();
    });

    if (actionNeededPayouts.length > 0) {
      actionNeededPayouts.forEach((row: any) => {
        const totalAmount = new BigNumber(row.amount);
        const idoFee = totalAmount.multipliedBy(
          row?.allocation?.ido.acquire_fee / 100
        );
        const amountToTransfer = totalAmount.minus(idoFee);

        const values = [
          row?.allocation?.owner?.wallet_address,
          amountToTransfer.toString(),
          row?.allocation?.ido?.token?.address,
          row?.allocation?.ido?.token.name,
        ];
        csvRows.push(values.join(","));
      });

      const csvContent = csvRows.join("\n");
      const blob = new Blob([csvContent], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.setAttribute("hidden", "");
      a.setAttribute("href", url);
      a.setAttribute("download", "payouts.csv");
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } else {
      message.error("No Payouts unlocked to send out");
    }
  };

  const bulkApprove = async () => {
    setLoading(true);

    try {
      if (approveTxHash) {
        const response = await bulkApprovePayouts(id, approveTxHash);
        if (response.status === "success") {
          await loadPayouts();
          setApproveTxHash("");
          message.success("Allocations approved");
        } else {
          message.error(response.message);
        }
      }
    } catch (error) {
      message.error(String(error));
    }
    setLoading(false);
  };

  const handleManualApprove = async () => {
    try {
      await fulfillManualPayout(selectedPayout.id, manualTxHash);
      message.success("Payout manually approved");
      setIsManualModalVisible(false);
      setManualTxHash("");
      loadPayouts();
    } catch (error) {
      message.error("Failed to approve payout manually");
    }
  };

  const exportUpcomingToCSV = () => {
    const csvRows = [];
    const headers = ["recipient", "amount", "token_address", "token_name"];
    csvRows.push(headers.join(","));

    const selectedUpcomingPayouts = upcomingPayouts.filter((payout: any) => {
      return payout.payout_part === selectedPayoutPart;
    });

    if (selectedUpcomingPayouts.length > 0) {
      selectedUpcomingPayouts.forEach((row: any) => {
        const totalAmount = new BigNumber(row.amount);
        const idoFee = totalAmount.multipliedBy(
          row?.allocation?.ido.acquire_fee / 100
        );
        const amountToTransfer = totalAmount.minus(idoFee);

        const values = [
          row?.allocation?.owner?.wallet_address,
          amountToTransfer.toString(),
          row?.allocation?.ido?.token?.address,
          row?.allocation?.ido?.token.name,
        ];
        csvRows.push(values.join(","));
      });

      const csvContent = csvRows.join("\n");
      const blob = new Blob([csvContent], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.setAttribute("hidden", "");
      a.setAttribute("href", url);
      a.setAttribute("download", "upcoming_payouts.csv");
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      setIsExportModalVisible(false);
      setSelectedPayoutPart(null);
    } else {
      message.error("No payouts found for selected part");
    }
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            defaultBg: "rgba(255, 255, 255, 0.30)",
            defaultColor: "white",
          },
          Tabs: {
            inkBarColor: "#33FFFF",
            lineWidthBold: 3,
            colorBorderSecondary: "#303030",
          },
        },
      }}
    >
      <Layout
        style={{
          backgroundColor: "#0C0A1F",
          alignItems: "center",
          padding: md ? "36px 48px" : sm ? "24px 24px" : " 24px 16px",
        }}
      >
        <Row style={{ width: "100%", marginBottom: "8px" }}>
          <Text size="sm" style={{ fontSize: "20px", fontWeight: "600" }}>
            {token_name}
          </Text>
        </Row>
        <Row
          style={{
            maxWidth: "1282px",
            width: "100%",
            flexDirection: "column",
            overflow: "auto",
          }}
        >
          <Tabs activeKey={activeTab} onChange={handleTabChange}>
            <TabPane
              tab={
                <Text
                  size="sm"
                  style={{
                    color: activeTab === "actionNeeded" ? "#33FFFF" : "white",
                  }}
                >
                  Action Needed
                </Text>
              }
              key="actionNeeded"
            >
              <Row
                style={{
                  alignItems: "flex-end",
                  justifyContent: "end",
                  width: "100%",
                  display: "flex",
                  marginBottom: "20px",
                  gap: "10px",
                }}
              >
                <Input
                  value={approveTxHash}
                  onChange={(e) => setApproveTxHash(e.target.value)}
                  type="text"
                  placeholder="Enter transaction Hash"
                  style={{ maxWidth: "335px" }}
                />
                <Button loading={loading} size="middle" onClick={bulkApprove}>
                  Bulk approve
                </Button>
                <Button
                  type="primary"
                  loading={loading}
                  onClick={exportToCSV}
                  style={{ marginLeft: "12px" }}
                >
                  Export payout list (CSV)
                </Button>
              </Row>
              <Table
                dataSource={filteredPayouts}
                columns={columns}
                rowKey="id"
                style={{ width: "100%" }}
              />
            </TabPane>
            <TabPane
              tab={
                <Text
                  size="sm"
                  style={{
                    color:
                      activeTab === "upcomingPayouts" ? "#33FFFF" : "white",
                  }}
                >
                  Upcoming Payouts
                </Text>
              }
              key="upcomingPayouts"
            >
              <Row
                style={{
                  alignItems: "flex-end",
                  justifyContent: "end",
                  width: "100%",
                  display: "flex",
                  marginBottom: "20px",
                }}
              >
                <Button
                  type="primary"
                  onClick={() => setIsExportModalVisible(true)}
                  style={{ marginLeft: "12px" }}
                >
                  Export upcoming (CSV)
                </Button>
              </Row>
              <Table
                dataSource={upcomingPayouts}
                columns={upcoming_columns}
                rowKey="id"
                style={{ width: "100%" }}
              />
            </TabPane>
            <TabPane
              tab={
                <Text
                  size="sm"
                  style={{
                    color:
                      activeTab === "approvedPayouts" ? "#33FFFF" : "white",
                  }}
                >
                  Approved Payouts
                </Text>
              }
              key="approvedPayouts"
            >
              <Table
                dataSource={approvedPayouts}
                columns={approved_columns}
                rowKey="id"
                style={{ width: "100%" }}
              />
            </TabPane>
          </Tabs>
        </Row>

      </Layout>
      <Modal
      className="approve-modal"
        title="Manually approve payout"
        open={isManualModalVisible}
        onCancel={() => {
          setIsManualModalVisible(false);
          setManualTxHash("");
        }}
        styles={{
          content: {
            padding: "36px",
            backgroundColor: "#120F2D",
          },
        }}
        footer={[
          <Button
            key="cancel"
            onClick={() => {
              setIsManualModalVisible(false);
              setManualTxHash("");
            }}
            style={{
              background: "rgba(255, 255, 255, 0.1)",
              borderColor: "transparent",
              color: "black",
            }}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleManualApprove}
            disabled={!manualTxHash}
            style={{
              background: "#33FFFF",
              borderColor: "transparent",
              color: "black",
            }}
          >
            Submit
          </Button>,
        ]}
        style={{
          background: "#0C0A1F",
          borderRadius: "12px",
        }}
        modalRender={(modal) => (
          <div
            style={{
              background: "#0C0A1F",
              borderRadius: "12px",
            }}
          >
            {modal}
          </div>
        )}
      >
        <div style={{ color: "black" }}>
          <Text size="sm" style={{ marginBottom: "8px", textAlign:"center" }}>
            You're about to approve this payout manually. Enter the tx hash of this payout and save.
          </Text>
          <Input
            placeholder="Enter a tx hash"
            value={manualTxHash}
            onChange={(e) => setManualTxHash(e.target.value)}
            style={{
              marginTop: "16px",
              background: "rgba(255, 255, 255, 0.1)",
              border: "1px solid rgba(255, 255, 255, 0.2)",
              borderRadius: "6px",
              color: "black",
            }}
          />
        </div>
      </Modal>
      {/* <Modal
        title="Export Upcoming"
        open={isExportModalVisible}
        onCancel={() => {
          setIsExportModalVisible(false);
          setSelectedPayoutPart(null);
        }}
        footer={[
          <Button
            key="cancel"
            onClick={() => {
              setIsExportModalVisible(false);
              setSelectedPayoutPart(null);
            }}
            style={{
              background: "rgba(255, 255, 255, 0.1)",
              borderColor: "transparent",
              color: "white",
            }}
          >
            Cancel
          </Button>,
          <Button
            key="export"
            type="primary"
            onClick={exportUpcomingToCSV}
            disabled={!selectedPayoutPart}
            style={{
              background: "#33FFFF",
              borderColor: "transparent",
              color: "black",
            }}
          >
            Export
          </Button>,
        ]}
        style={{
          background: "#0C0A1F!important",
          borderRadius: "12px",
        }}
        modalRender={(modal) => (
          <div
            style={{
              background: "#0C0A1F",
              borderRadius: "12px",
            }}
          >
            {modal}
          </div>
        )}
      >
        <div style={{ color: "white" }}>
          <p style={{ marginBottom: "16px" }}>Select payout part to export:</p>
          <Select
            style={{ width: "100%" }}
            placeholder="Select payout part"
            onChange={(value) => setSelectedPayoutPart(value)}
            value={selectedPayoutPart}
          >
            {Array.from(
              new Set(upcomingPayouts.map((p: any) => p.payout_part))
            ).map((part: any) => (
              <Select.Option key={part} value={part}>
                Part {part} of{" "}
                {upcomingPayouts[0]?.allocation?.ido?.total_release_months}
              </Select.Option>
            ))}
          </Select>
        </div>
      </Modal> */}
      <Modal
            className="approve-modal"
        title="Export Upcoming"
        open={isExportModalVisible}
        onCancel={() => {
          setIsExportModalVisible(false);
          setSelectedPayoutPart(null);
        }}
        styles={{
          content: {
            padding: "36px",
            backgroundColor: "#120F2D",
          },
        }}
        footer={[
          <Button
            key="cancel"
            onClick={() => {
              setIsExportModalVisible(false);
              setSelectedPayoutPart(null);
            }}
            style={{
              background: "rgba(255, 255, 255, 0.1)",
              borderColor: "transparent",
              color: "white",
            }}
          >
            Cancel
          </Button>,
          <Button
            key="export"
            type="primary"
            onClick={exportUpcomingToCSV}
            disabled={!selectedPayoutPart}
            style={{
              background: "#33FFFF",
              borderColor: "transparent",
              color: "black",
            }}
          >
            Export
          </Button>,
        ]}
        style={{
          background: "#0C0A1F",
          borderRadius: "12px",
        }}
        modalRender={(modal) => (
          <div
            style={{
              background: "#0C0A1F",
              borderRadius: "12px",
            }}
          >
            {modal}
          </div>
        )}
      >
        <div style={{ color: "white" }}>
  <p style={{ marginBottom: "16px" }}>Select payout part to export:</p>
  <Select
    style={{ width: "100%" }}
    placeholder="Select payout part"
    onChange={(value) => setSelectedPayoutPart(value)}
    value={selectedPayoutPart}
  >
    {Array.from(
      new Set(upcomingPayouts.map((p: any) => p.payout_part))
    ).map((part: any) => {
      // Get total release months and tge from the first item in upcomingPayouts
      const totalReleaseMonths = upcomingPayouts[0]?.allocation?.ido?.total_release_months ?? 0;
      const tge = upcomingPayouts[0]?.allocation?.ido?.tge;

      // Adjust total release months if tge has a value (is not null or undefined)
      const adjustedTotalReleaseMonths = (tge) ? totalReleaseMonths + 1 : totalReleaseMonths;

      return (
        <Select.Option key={part} value={part}>
          Part {part} of {adjustedTotalReleaseMonths}
        </Select.Option>
      );
    })}
  </Select>
</div>
      </Modal>
    </ConfigProvider>
  );
};

export default PayoutDetail;
