import {
  Button,
  Checkbox,
  Col,
  ConfigProvider,
  Form,
  Input,
  Layout,
  Row,
  Select,
  Upload,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import Text from "../components/Text";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import { UploadOutlined } from "@ant-design/icons";
import {
  Networks,
  CreateTokenApi,
  DeleteToken,
  EditTokenApi,
} from "../api/ApiCalls";
import { NetworkAPIType, TokenAPIType, Tokens } from "../utils";
import { useLocation, useNavigate } from "react-router-dom";
import deleteOutlined from "../assets/DeleteOutlined.svg";
import DeleteModal from "../components/DeleteModal";

const CreateToken = () => {
  const location = useLocation();
  const obj: Tokens = location.state?.obj;
  const { xs, sm, md, lg, xl, xxl } = useBreakpoint();
  const [network, setNetwork] = useState<NetworkAPIType[]>([]);
  const { Option } = Select;
  const [file, setFile] = useState<File>();
  const [loader, setLoader] = useState<boolean>(false);
  const navigate = useNavigate();
  const [tokenFlage, setTokenFlage] = useState<boolean>(true);
  const [deleteLoader, setDeleteLoader] = useState<boolean>(false);
  const [updateLoader, setUpdateLoader] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showDeleteModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // const handleDelete = () => {
  //   // Perform delete action here
  //   console.log("Deleted");
  //   setIsModalOpen(false);
  // };

  const handleFileChange = (info: any) => {
    const fileList = [...info.fileList];
    setFile(fileList.length > 0 ? fileList[0].originFileObj : null);
  };

  const GetNetworks = async () => {
    try {
      const res = await Networks();
      if (res) {
        //console.log("Networks API res ", res);
        setNetwork(res);
      }
    } catch (err) {
      console.log("Error While Fetch Networks API", err);
    }
  };

  const createToken = async (formdata: FormData) => {
    setLoader(true);
    try {
      const res = await CreateTokenApi(formdata);
      if (res) {
        setLoader(false);
        message.success("Token Created");
        navigate("/tokens-overview");
      }
    } catch (err) {
      console.log("erroe While creating token", err);
      message.error("Error While Creating a Token");
      setLoader(false);
    }
  };

  const handleFormFinish = (values: FormValues) => {
    const selectedNetwork = network.find(
      (token) => token.id === values.network
    );

    const formData = new FormData();
    formData.append("network", values.network);
    formData.append("name", values.name);
    formData.append("symbol", values.symbol);
    formData.append("address", values?.address ? values.address : "");
    formData.append("decimals", values?.decimals ? values.decimals : "");
    if (file) {
      formData.append("image", file);
    }
    if (values.send_token === true) {
      formData.append("send_token", "true");
    } else {
      formData.append("send_token", "false");
    }

    if (obj) {
      EditToken(obj.id, formData);
    } else {
      createToken(formData);
    }
  };

  const HandleDelete = async (id: string) => {
    setDeleteLoader(true);
    try {
      const res = await DeleteToken(id);
      if (res) {
        message.success("Token Deleted");
        setDeleteLoader(false);
        navigate("/tokens-overview");
      }
    } catch (err) {
      console.log("Error While Deleting ", err);
      message.error("Error While Deleting Token");
      setDeleteLoader(false);
    }
  };

  const EditToken = async (id: string, formdata: FormData) => {
    setUpdateLoader(true);
    try {
      const res = await EditTokenApi(id, formdata);
      if (res) {
        message.success("Token Updated");
        setDeleteLoader(false);
        navigate("/tokens-overview");
      }
    } catch (err) {
      console.log("Error while Updating Token");
      message.error("Error While Updating Token");
      setUpdateLoader(false);
    }
  };

  useEffect(() => {
    GetNetworks();
  }, []);
  return (
    <Layout
      style={{
        justifyContent: obj ? "" : "center",
        backgroundColor: "#0C0A1F",
        alignItems: obj ? "" : "center",
        padding: md ? "36px 48px" : sm ? "24px 24px" : " 24px 16px",
      }}
    >
      <Row
        style={{
          width: xxl
            ? "642px"
            : xl
            ? "574px"
            : lg
            ? "540px"
            : md
            ? "586px"
            : sm
            ? "504px"
            : "363px",
          padding: xxl
            ? "56px"
            : xl
            ? "36px"
            : lg
            ? "36px"
            : md
            ? "36px"
            : sm
            ? "36px"
            : "24px",
          backgroundColor: obj ? "" : "rgba(255, 255, 255, 0.30)",
          borderRadius: "12px",
          overflowY: "auto",
        }}
      >
        {!obj && (
          <Row
            style={{
              justifyContent: "center",
              width: "100%",
              marginBottom: "20px",
            }}
          >
            <Text
              size="sm"
              style={{ fontSize: "20px", width: "100%", textAlign: "center" }}
            >
              Create an Token
            </Text>
          </Row>
        )}
        <Row style={{ width: "100%" }}>
          <Form
            name="CreateToken"
            style={{ width: "100%" }}
            onFinish={handleFormFinish}
            initialValues={{
              name: obj?.name || "",
              symbol: obj?.symbol || "",
              address: obj?.address || "",
              network: obj?.network.id || undefined,
              decimals: obj?.decimals || "",
              send_token: obj?.send_token || false,
            }}
          >
            <Row style={{ flexDirection: "column", gap: "8px" }}>
              <Text size="xs" style={{ color: "#909090" }}>
                Token name
              </Text>
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please input token name!",
                  },
                ]}
              >
                <Input placeholder="Enter a token name" size="large" />
              </Form.Item>
            </Row>

            <Row style={{ flexDirection: "column", gap: "8px" }}>
              <Text size="xs" style={{ color: "#909090" }}>
                Symbol
              </Text>
              <Form.Item
                name="symbol"
                rules={[
                  {
                    required: true,
                    message: "Please input symbol",
                  },
                ]}
              >
                <Input placeholder="Enter a symbol" size="large" />
              </Form.Item>
            </Row>

            <Row style={{ flexDirection: "column", gap: "8px" }}>
              <Text size="xs" style={{ color: "#909090" }}>
                Token address (optional)
              </Text>
              <Form.Item
                name="address"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please input wallet address!",
                //   },
                // ]}
              >
                <Input placeholder="Enter a wallet address" size="large" />
              </Form.Item>
            </Row>

            <Row style={{ flexDirection: "column", gap: "4px" }}>
              <Text size="xs" style={{ color: "#909090" }}>
                Network
              </Text>
              <Form.Item
                name="network"
                rules={[{ required: true, message: "Please select network!" }]}
              >
                <Select placeholder="Select a network" size="large">
                  {network.map((token) => (
                    <Option key={token.id} value={token.id}>
                      {token.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Row>

            <Row style={{ flexDirection: "column", gap: "8px" }}>
              <Text size="xs" style={{ color: "#909090" }}>
                Decimals
              </Text>
              <Form.Item
                name="decimals"
                rules={[
                  {
                    required: true,
                    message: "Please input decimals",
                  },
                ]}
              >
                <Input
                  placeholder="Enter a amount of decimals"
                  size="large"
                  onKeyPress={(e) => {
                    const charCode = e.which ? e.which : e.keyCode;
                    const value = (e.target as HTMLInputElement).value;

                    if (
                      (charCode !== 46 || value.indexOf(".") !== -1) &&
                      (charCode < 48 || charCode > 57)
                    ) {
                      e.preventDefault();
                    }
                  }}
                />
              </Form.Item>
            </Row>

            <Row style={{ flexDirection: "column", gap: "8px" }}>
              <Text size="xs" style={{ color: "#909090" }}>
                Token image
              </Text>
              <ConfigProvider
                theme={{
                  components: {
                    Button: {
                      defaultBg: "#120F2D",
                      defaultColor: "White",
                    },
                  },
                }}
              >
                <Form.Item
                  name=""
                  rules={[
                    {
                      required: obj ? false : true,
                      message: "Please upload Image",
                    },
                  ]}
                  style={{ width: "100%" }}
                >
                  <Upload
                    onChange={handleFileChange}
                    beforeUpload={() => false}
                  >
                    <Button icon={<UploadOutlined />} style={{ width: "100%" }}>
                      Upload
                    </Button>
                  </Upload>
                </Form.Item>
              </ConfigProvider>

              {obj?.image && tokenFlage && (
                <Row style={{ marginBottom: "10px" }}>
                  <Col
                    style={{
                      width: "100%",
                      border: "1px solid rgba(255, 255, 255, 0.16)",
                      borderRadius: "6px",
                      padding: "8px",
                      marginTop: "-8px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Col
                      style={{
                        display: "flex",
                        gap: "5px",
                        alignItems: "center",
                      }}
                    >
                      <img
                        style={{
                          width: "36px",
                          height: "36px",
                          borderRadius: "50%",
                        }}
                        src={obj ? obj?.image : ""}
                      />
                      {/* <Text size="sm" style={{ color: "#33FFFF" }}>
                    {obj.project_image}
                  </Text> */}
                    </Col>
                    {/* <img
                      src={deleteOutlined}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setTokenFlage(false);
                      }}
                    /> */}
                  </Col>
                </Row>
              )}
            </Row>

            <Row>
              <Form.Item
                name="send_token"
                valuePropName="checked"
                //wrapperCol={{ offset: 8, span: 16 }}
              >
                <Checkbox>This token is a Valid Stable Coin</Checkbox>
              </Form.Item>
            </Row>

            {!obj && (
              <Form.Item>
                <Button
                  style={{ width: "100%", marginTop: "14px" }}
                  type="primary"
                  htmlType="submit"
                  size="large"
                  loading={loader}
                >
                  Create Token
                </Button>
              </Form.Item>
            )}

            {obj && (
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item>
                    <Button
                      style={{ width: "100%", marginTop: "14px" }}
                      type="primary"
                      htmlType="submit"
                      size="large"
                    >
                      Save changes
                    </Button>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item>
                    <ConfigProvider
                      theme={{
                        components: {
                          Button: {
                            defaultBorderColor: "red",
                            defaultColor: "red",
                            defaultHoverColor: "red",
                          },
                        },
                      }}
                    >
                      <Button
                        style={{ width: "100%", marginTop: "14px" }}
                        size="large"
                        onClick={() => {
                          //  HandleDelete(obj.id);
                          showDeleteModal();
                        }}
                        loading={deleteLoader}
                      >
                        Delete Token
                      </Button>
                    </ConfigProvider>
                  </Form.Item>
                </Col>
              </Row>
            )}
          </Form>
        </Row>
      </Row>
      <DeleteModal
        title="Delete token"
        text1="If you delete this token, all IDO’s that contains this token will also be deleted."
        text2="Are you sure you want to delete this token? You can’t undo this action."
        open={isModalOpen}
        onCancel={handleCancel}
        onDelete={() => {
          HandleDelete(obj.id);
          handleCancel();
        }}
        DeleteButtonName="Delete Token"
      />
    </Layout>
  );
};

export default CreateToken;

interface FormValues {
  name: string;
  symbol: string;
  address?: string;
  network: string;
  decimals?: string;
  send_token: boolean;
}
