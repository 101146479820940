import axios from "../axios";

export const getUnfilledAllocations = async () => {
  try {
    const endPoint = "api/dashboard/allocations/?fulfilled=0";
    const res = await axios.get<any>(endPoint);
    if (!res?.data) throw "Something went wrong";
    return res.data;
  } catch (err: any) {
    console.log("error while getAllocations API>>>", err.response.data);
    return Promise.reject(err);
  }
};

export const getfilledAllocations = async () => {
  try {
    const endPoint = "api/dashboard/allocations/?fulfilled=1";
    const res = await axios.get<any>(endPoint);
    if (!res?.data) throw "Something went wrong";
    return res.data;
  } catch (err: any) {
    console.log("error while getAllocations API>>>", err.response.data);
    return Promise.reject(err);
  }
};

export const fulfillPayout = async (id: any, transaction_hash: string) => {
  try {
    const endPoint = "api/dashboard/payouts/" + id + "/fulfill-payout/";
    const res = await axios.post<any>(endPoint, { transaction_hash });
    if (!res?.data) throw "Something went wrong";
    return res.data;
  } catch (err: any) {
    console.log("error while fulfillPayout API>>>", err.response.data);
    return Promise.reject(err);
  }
};

export const bulkApprovePayouts = async (id: any, transaction_hash: string) => {
  try {
    const endPoint = "api/dashboard/ido/" + id + "/bulk-approve/";
    const res = await axios.post<any>(endPoint, { transaction_hash });
    if (!res?.data) throw "Something went wrong";
    return res.data;
  } catch (err: any) {
    console.log("error while bulkApprovePayouts API>>>", err.response.data);
    return Promise.reject(err);
  }
};

export const fulfillManualPayout = async (payoutId: number, transaction_hash: string) => {
  try {
    const endPoint = `api/dashboard/payouts/${payoutId}/fulfill-manual-payout/`;
    const res = await axios.post<any>(endPoint, { transaction_hash });
    if (!res?.data) throw "Something went wrong";
    return res.data;
  } catch (err: any) {
    console.log("error while fulfillManualPayout API>>>", err.response.data);
    return Promise.reject(err);
  }
};