import Layout from "antd/es/layout/layout";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Row, Table } from "antd";
import type { TableProps } from "antd";
import { GetIdo } from "../api/ApiCalls";
import { idoState, setido } from "../redux/reducers";
import Text from "../components/Text";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import { PlusOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { IdoListType } from "../utils";
import { ValuesFormatDecimalAndCommas } from "../Services/CommonServices";

const Home = () => {
  const dispatch = useDispatch();
  const { xs, sm, md, lg, xl, xxl } = useBreakpoint();
  const idoList = useSelector(idoState);
  const navigate = useNavigate();
  const fetchIdo = async () => {
    try {
      const res = await GetIdo();
      console.log("res", res);
      if (res) {
        dispatch(setido(res));
      }
    } catch (err) {
      console.log("Error While Fetch Ido ", err);
    }
  };

  useEffect(() => {
    fetchIdo();
  }, []);

  const columns: TableProps<IdoListType>["columns"] = [
    {
      title: "Offer name",
      dataIndex: "token_name",
      key: "token_name",
    },
    {
      title: "Launch price",
      //dataIndex: "price",
      key: "price",
      render: (record: IdoListType) => {
        return (
          <Text size="sm">
            {ValuesFormatDecimalAndCommas(record.price)}{" "}
            {record?.exchange_tokens[0]?.symbol}
          </Text>
        );
      },
    },
    {
      title: "Tokens to sell",
      //dataIndex: "amount",
      key: "amount",
      render: (record: IdoListType) => {
        return (
          <Text size="sm">
            {ValuesFormatDecimalAndCommas(record.amount)}{" "}
            {record?.token?.symbol}
          </Text>
        );
      },
    },
    {
      title: "Participants",
      dataIndex: "investors",
      key: "investors",
    },
    {
      title: "Current stage",
      dataIndex: "stage",
      key: "stage",
    },
  ];

  const data: IdoListType[] = idoList.map((ido) => ({
    key: ido.id.toString(),
    ...ido,
  }));

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: IdoListType[]) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record: IdoListType) => ({
      disabled: record.wallet_address === "Disabled User", // Column configuration not to be checked
      name: record.wallet_address,
    }),
  };

  return (
    <Layout
      style={{
        backgroundColor: "#0C0A1F",
        alignItems: "center",
        padding: md ? "36px 48px" : sm ? "24px 24px" : " 24px 16px",
      }}
    >
      <Row
        style={{
          padding: "8px",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          maxWidth: "1282px",
        }}
      >
        <Text size="sm">All Offers</Text>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          style={{ fontWeight: "400" }}
          onClick={() => {
            navigate("/create");
          }}
        >
          Create Offer
        </Button>
      </Row>
      <Row
        style={{
          maxWidth: "1282px",
          width: "100%",
          flexDirection: "column",
          overflow: "auto",
        }}
      >
        <Row style={{ minWidth: "857px", overflowX: "auto" }}>
          <Table
            size="large"
            rowSelection={{
              type: "checkbox",
              ...rowSelection,
            }}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  navigate("/edit", { state: { obj: { ...record } } });
                },
                style: { cursor: "pointer" },
              };
            }}
            columns={columns}
            dataSource={data}
            pagination={{ pageSize: 8 }}
            // expandable={
            //   !md
            //     ? {
            //         expandedRowRender: (record) => (
            //           <div style={{ padding: "0px", margin: "-16px" }}>
            //             <Row className="recent_Offer_tabel_row">
            //               <Text size="sm">Token Name</Text>
            //               <Text size="sm">{record.token}</Text>
            //             </Row>{" "}
            //             <Row className="recent_Offer_tabel_row">
            //               <Text size="sm">Amount</Text>
            //               <Text size="sm">{record.amount}</Text>
            //             </Row>{" "}
            //             <Row className="recent_Offer_tabel_row">
            //               <Text size="sm">Action</Text>
            //               <button className="Tabel_Button_view">View</button>
            //             </Row>
            //           </div>
            //         ),
            //         expandIcon: ({ expanded, onExpand, record }) =>
            //           expanded ? (
            //             <UpOutlined
            //               onClick={(e: any) => onExpand(record, e)}
            //               style={{ color: "#33FFFF" }}
            //             />
            //           ) : (
            //             <DownOutlined
            //               onClick={(e: any) => onExpand(record, e)}
            //               style={{ color: "#909090" }}
            //             />
            //           ),
            //         expandIconColumnIndex: 3,
            //       }
            //     : undefined
            // }
            style={{ width: "100%" }}
          />
        </Row>
      </Row>
    </Layout>
  );
};

export default Home;

function shortenString(str: string, length = 4) {
  if (str.length <= length * 2 + 3) {
    return str;
  }
  const start = str.substring(0, length);
  const end = str.substring(str.length - length);
  return `${start}...${end}`;
}
